<template>
  <div>
    <nav class="navbar navbar-expand-md nav-shadow">
        <a class="navbar-brand">
            <img src="@/assets/logo.png" alt="" style="max-width:55px;">
            <span class="SS_MM_AWM_Ltd_"> SS MM AWM Ltd. </span>
        </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
        <!-- <li class="nav-item px-lg-3 ">
          <a class="nav-link n-item" href="#">
            Home
          </a>
      </li>
        <li class="nav-item px-lg-3 ">
          <a class="nav-link n-item" href="#">
            About us
          </a>
      </li>
      <li class="nav-item px-lg-3 ">
          <a class="nav-link n-item" href="#">
            The Team
          </a>
      </li>
      <li class="nav-item px-lg-3 ">
          <a class="nav-link n-item" href="#">
            Contact us
          </a>
      </li> -->
  </ul>
  </div>
  
</nav>
  </div>
</template>

<script>
export default {

}
</script>

<style>

  .navbar-nav {
            margin-left: auto;
        }
        .nav-shadow {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 81px 0px rgba(0, 0, 0, 0.2);
}
.SS_MM_AWM_Ltd_ {
  font-family:Arial;
  /* font-family: "BaskOldFace"; */
  color: rgb(60, 83, 88);
  line-height: 1.062;
  text-align: left;
  letter-spacing: 2px;
}
.n-item {
  font-family:Arial;
  color: rgb(104, 128, 133);
  line-height: 1.062;
  font-size: 24px;
  text-align: left;
}
 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgb(84, 116, 123);
} 
</style>