<template>
  <div class="main-footer">
    <div class="inner-footer">
            <span class="d-block footer-title">
            Contact us
          </span>
          <span class="d-block seven"></span>
            <span class="d-block para-footer">
                Intershore Consultant
                <br>
                <br>
Intershore Chambers, Road Town, Tortola, British Virgin Islands
<br>
                <br>
                For more info send us an email: <b> info@ssmmawm.com </b>
            </span>
            <div class="mt-5">
      <img src="@/assets/footer-log.png" style="float: right;max-width:150px;" alt="">
    </div>   
          </div>

       
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>