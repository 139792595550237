<template>
  <div class="container-fuild">
    <div class="home">
    <div class="image-back position-relative">
      <img src="@/assets/bg-one.jpg" class="bg-one" alt="">
      <div class="position-absolute" style="    top: -13px;
    width: 100%;">
        <div class="row">
        <div class="col-12 col-md-8 p-0 m-0">
          <div class="inner">
            <span class="d-block Capital_meets_Innovation">
            Capital meets Innovation 
          </span>
          <span class="d-block Shape_7_copy"></span>
            <span class="d-block para" style="text-align:justify;">
              We build a dynamic environment where financial resources and cutting-edge ideas intersect to create new opportunities, unleash breakthroughs, and drive growth.
            </span>
          </div>
          
        </div>
        
      </div>
      </div>
    </div>
    <div class="second">
      <div class="row">
        <div class="col-12 p-0 m-0">
          <div class="inners" style="border-radius: 0px;width:100%;margin-top:0px;">
            <div class="row p-0 m-0">
              <div class="col-md-7 col-12 p-0 m-0">
                <span class="d-block Capital_meets_Innovation">
            About us 
          </span>
          <span class="d-block Shape_7_copy"></span>
            <span class="d-block para" style="text-align:justify;">
              <p>We are an asset management company that specializes in the strategic acquisition of land in the United Kingdom and European Union. Our expertise lies in trading discounted real estate assets with top auction companies while also adeptly managing assets in trust.</p>

              <p>Moreover, we actively engage in joint venture real estate development initiatives, collaborating with a diverse range of partners and asset owners to participate in high-value real estate projects.</p>

              <p>Our operations are primarily focused in the United Kingdom and European Union, with key countries of interest including Belgium, France, Germany, and the Netherlands. However, we are continuously expanding our reach to include other nations in the near future.</p>
            </span>  
              </div>
              <div class="col-md-5" style="text-align: center;margin: auto;"> <img src="@/assets/logo-v.png" class="d-block mt-5 mx-auto" alt=""> </div>
            </div>
            <div>
             
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
    <div class="third">
      <div class="image-back position-relative">
      <img src="@/assets/bg-second.jpg" class="bg-second" alt="">
      <div class="position-absolute second-top">
        <div class="row justify-content-end">
        <div class="col-12 col-md-8 p-0 m-0">
          <div class="inner-second">
            <span class="d-block Capital_meets_Innovation">
            The Team 
          </span>
          <span class="d-block Shape_7_copy"></span>
            <span class="d-block para" style="text-align:justify;">
              With a combined wealth of experience spanning multiple industries, our team has built extensive careers in the corporate world, real estate, and technology, leveraging their diverse backgrounds and expertise to deliver unparalleled insights, innovative solutions, and exceptional results for our clients.
            </span>
          </div>
          
        </div>
        
      </div>
      </div>
    </div>
    </div>
  </div>
  </div>
  
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
<style>
</style>
