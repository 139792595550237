<template>
  <div id="app">
    <div id="nav">
      <nav-bar></nav-bar>
    </div>
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/views/Footer.vue'
export default {
  components:{
    Footer,
    NavBar,
  }
}
</script>

<style>

</style>
